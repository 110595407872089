// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qDJxM9o3WA31eACeuEu16g\\=\\={min-height:100vh;color:#303131;font-family:Roboto,sans-serif;background-color:#fff}.rrc6tZTdXbOAuOR8oGRjPQ\\=\\={width:100px;height:auto}@media screen and (min-width:600px){.rrc6tZTdXbOAuOR8oGRjPQ\\=\\={width:159px;height:45px}}.rrc6tZTdXbOAuOR8oGRjPQ\\=\\= .APFkoUFnn05E5yw8MWey1Q\\=\\={width:100%;height:100%;-o-object-fit:contain;object-fit:contain;-o-object-position:center;object-position:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "qDJxM9o3WA31eACeuEu16g==",
	"logoWrapper": "rrc6tZTdXbOAuOR8oGRjPQ==",
	"logo": "APFkoUFnn05E5yw8MWey1Q=="
};
module.exports = ___CSS_LOADER_EXPORT___;
