//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRate } from '@finuslugi/common-utils';
import { money, term } from '@/utils/filters';

import { uiButton as UiButton } from '@moex/ui-framework-next';

export default {
  name: 'LandingProductsItem',
  components: {
    UiButton,
  },
  filters: {
    formatRate,
    money,
    term,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    texts: {
      type: Object,
      default: () => ({}),
    },
    gtms: {
      type: Object,
      default: () => ({}),
    },
    isNonPlatform: {
      type: Boolean,
      default: false,
    },
  },
};
