//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import merge from 'lodash/merge';

export default {
  name: 'LandingProceed',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    items() {
      return this.baseConfig.ITEMS || [];
    },
    baseConfig() {
      return merge({}, this.$siteConfig.LANDING.CORE, this.$siteConfig.LANDING.PROCEED, this.config);
    },
  },
};
