//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InterfaxAbout',
  computed: {
    config() {
      return {
        ...this.$siteConfig.INTERFAX.CORE,
        ...this.$siteConfig.INTERFAX.ABOUT,
      };
    },
    items() {
      return this.config.ITEMS || [];
    },
  },
};
