//
//
//
//

import Interfax from '@/components/Interfax/Interfax';

export default {
  name: 'InterfaxLanding',
  components: { Interfax },
};
