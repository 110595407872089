//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line import/no-unresolved
import FinuslugiLogo from '@/assets/images/finuslugi-logo.svg?inline';

export default {
  name: 'TheHeader',
  components: {
    FinuslugiLogo,
  },
};
