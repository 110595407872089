// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mVJTpioShyXg\\+v5bf3sjkQ\\=\\={background-color:#fff;padding:32px 0}.OBsfnWCPUjfxd3NxBBIuFg\\=\\={display:grid;grid-gap:24px}.nanou19L\\+Zd7HNGjIUmbtg\\=\\={text-align:center}.RF24n5i04LsZGQwMjhSMWg\\=\\={margin:0;font-weight:500;font-size:32px;line-height:40px}.c-K0i-H3MWTqnPz5Xen2Kw\\=\\={margin-top:24px;font-weight:400;font-size:18px;line-height:24px}.LTZ1HfcbKa8FTmyPVSZ9ZQ\\=\\={width:100%}.LTZ1HfcbKa8FTmyPVSZ9ZQ\\=\\=,.TSECjAlNaAWr6kcRResDng\\=\\={margin-top:24px}.TSECjAlNaAWr6kcRResDng\\=\\={display:block;text-decoration:none;color:#999}.TSECjAlNaAWr6kcRResDng\\=\\=:hover{text-decoration:underline}.ShJ2xq9IHM84LXrlIL-rZA\\=\\={display:flex;justify-content:center;align-items:flex-end;width:100%}.ShJ2xq9IHM84LXrlIL-rZA\\=\\= .wIATk4FcoxkwM04u4ShfiQ\\=\\={height:170px;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center}@media screen and (min-width:600px){.nanou19L\\+Zd7HNGjIUmbtg\\=\\={margin:0 auto;max-width:600px}.LTZ1HfcbKa8FTmyPVSZ9ZQ\\=\\={width:220px}}@media screen and (min-width:900px){.OBsfnWCPUjfxd3NxBBIuFg\\=\\={grid-gap:32px;grid-template-columns:1fr 612px}.nanou19L\\+Zd7HNGjIUmbtg\\=\\={text-align:left}.RF24n5i04LsZGQwMjhSMWg\\=\\={margin-top:32px;font-weight:700;font-size:48px;line-height:58px}.c-K0i-H3MWTqnPz5Xen2Kw\\=\\={margin-top:36px}.LTZ1HfcbKa8FTmyPVSZ9ZQ\\=\\={margin-top:32px;width:272px}.ShJ2xq9IHM84LXrlIL-rZA\\=\\= .wIATk4FcoxkwM04u4ShfiQ\\=\\={height:359px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "mVJTpioShyXg+v5bf3sjkQ==",
	"container": "OBsfnWCPUjfxd3NxBBIuFg==",
	"content": "nanou19L+Zd7HNGjIUmbtg==",
	"title": "RF24n5i04LsZGQwMjhSMWg==",
	"caption": "c-K0i-H3MWTqnPz5Xen2Kw==",
	"button": "LTZ1HfcbKa8FTmyPVSZ9ZQ==",
	"link": "TSECjAlNaAWr6kcRResDng==",
	"imageWrapper": "ShJ2xq9IHM84LXrlIL-rZA==",
	"image": "wIATk4FcoxkwM04u4ShfiQ=="
};
module.exports = ___CSS_LOADER_EXPORT___;
