import { render, staticRenderFns } from "./LandingFAQ.vue?vue&type=template&id=41dbec55&"
import script from "./LandingFAQ.vue?vue&type=script&lang=js&"
export * from "./LandingFAQ.vue?vue&type=script&lang=js&"
import style0 from "./LandingFAQ.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./LandingFAQ.vue?vue&type=style&index=1&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonLocaleText: require('C:/Users/LyadovDA/Desktop/Moex LK/mp-promo-ui-credits/components/common/LocaleText.vue').default})
