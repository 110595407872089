// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cRFOC\\+lZ\\+ypqFnsQqFT6Ew\\=\\={position:-webkit-sticky;position:sticky;top:0;z-index:199;background-color:#fff}@media screen and (min-width:600px){.cRFOC\\+lZ\\+ypqFnsQqFT6Ew\\=\\={border-radius:0 0 32px 32px}}.jaNUzNGFvnBo0a4EoHrG-Q\\=\\={display:flex;align-items:center;justify-content:space-between;height:64px}@media screen and (min-width:600px){.jaNUzNGFvnBo0a4EoHrG-Q\\=\\={height:80px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "cRFOC+lZ+ypqFnsQqFT6Ew==",
	"content": "jaNUzNGFvnBo0a4EoHrG-Q=="
};
module.exports = ___CSS_LOADER_EXPORT___;
