//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import merge from 'lodash/merge';

import { mapLandingProducts } from '@/utils/helpers';

import LandingProductsItem from './LandingProductsItem';

export default {
  name: 'LandingProducts',
  components: {
    LandingProductsItem,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    platformProducts: [],
    nonPlatformProducts: [],
  }),
  async fetch() {
    const { COUNT } = this.baseConfig;
    const { ID: PLATFORM_ID } = this.baseConfig.PLATFORM || {};
    const { ID: NONPLATFORM_ID } = this.baseConfig.NON_PLATFORM || {};
    const url = `${this.$config.SHOWCASE_ROOT_URL}/money_data/CreditsGroup.json`;

    const [
      { data: platform },
      { data: nonPlatform },
    ] = await Promise.all([
      PLATFORM_ID ? this.$axios.get(`${url}?id=${PLATFORM_ID}&num=${COUNT}`) : Promise.resolve({ data: [] }),
      NONPLATFORM_ID ? this.$axios.get(`${url}?id=${NONPLATFORM_ID}&num=${COUNT}`) : Promise.resolve({ data: [] }),
    ]);

    this.platformProducts = platform?.credits_group || [];
    this.nonPlatformProducts = nonPlatform?.credits_group || [];
  },
  fetchOnServer: false,
  computed: {
    baseConfig() {
      return merge({}, this.$siteConfig.LANDING.CORE, this.$siteConfig.LANDING.PRODUCTS, this.config);
    },
    baseClasses() {
      const { $style: style } = this;

      return merge({
        container: style.container,
        left: style.left,
        title: style.title,
        caption: style.caption,
        content: style.content,
        items: style.items,
        item: style.item,
        titleContainer: style.titleContainer,
      }, this.classes);
    },
    platformItems() {
      const {
        URL_TEMPLATE,
        URL_TARGET,
        URL_PARAMS,
        CUSTOM_URL_TEMPLATE = {},
      } = this.baseConfig.PLATFORM || {};

      return this.mapProducts(this.platformProducts, {
        URL_TEMPLATE,
        URL_TARGET,
        URL_PARAMS,
        CUSTOM_URL_TEMPLATE,
        MARKETING: this.baseConfig.MARKETING,
      });
    },
    nonPlatformItems() {
      const {
        URL_TEMPLATE,
        URL_TARGET,
        URL_PARAMS,
        CUSTOM_URL_TEMPLATE = {},
      } = this.baseConfig.NON_PLATFORM || {};

      return this.mapProducts(this.nonPlatformProducts, {
        URL_TEMPLATE,
        URL_TARGET,
        URL_PARAMS,
        CUSTOM_URL_TEMPLATE,
        MARKETING: this.baseConfig.MARKETING,
      });
    },
    styles() {
      const { COUNT, MAXROW = 5 } = this.baseConfig;
      const platformLength = this.platformProducts.length;
      const nonPlatformLength = this.nonPlatformProducts.length;
      const currentBreakpoint = this.$ui.$breakpoints.current;
      const lenByBreakpoint = ({
        xs: COUNT,
        sm: COUNT,
        md: 4,
        lg: MAXROW,
        xl: MAXROW,
      })[currentBreakpoint];

      return {
        platform: {
          gridTemplateColumns: (lenByBreakpoint > platformLength) && `repeat(${platformLength}, min-content)`,
        },
        nonPlatform: {
          gridTemplateColumns: (lenByBreakpoint > nonPlatformLength) && `repeat(${nonPlatformLength}, min-content)`,
        },
      };
    },
  },
  methods: {
    mapProducts(products, {
      URL_TARGET,
      URL_TEMPLATE,
      URL_PARAMS,
      CUSTOM_URL_TEMPLATE,
      MARKETING,
    }) {
      return mapLandingProducts(products, {
        URL_TARGET,
        URL_PARAMS,
        URL_TEMPLATE,
        CUSTOM_URL_TEMPLATE,
        MARKETING,
        ...this.$config,
        ...this.$siteConfig,
      });
    },
  },
};
