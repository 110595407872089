// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5Bg7Tgk1RuUwgaI0R59e4A\\=\\={padding:24px 0 12px;background-color:#191d25}._5Bg7Tgk1RuUwgaI0R59e4A\\=\\=.s1YMkI09bZLcriRhtpEP-A\\=\\={background-color:#fff}@media screen and (min-width:900px){._5Bg7Tgk1RuUwgaI0R59e4A\\=\\={padding:80px 0 20px}}@media screen and (min-width:900px){.BE1dIrvt8SRz0CMOP-y\\+bQ\\=\\={padding:40px;background-color:#fff}}@media screen and (min-width:1200px){.BE1dIrvt8SRz0CMOP-y\\+bQ\\=\\={border-radius:20px}}@media screen and (max-width:899px){.XZLqK5clWFaFfoOx9\\+XoVg\\=\\={padding:40px;background-color:#fff;border-radius:20px}}.gss29k1XxDsjtf\\+XjsIt3Q\\=\\={margin:0;font-weight:500;font-size:18px;line-height:24px;text-align:center}.AX4vI7K6WztjrMY9Hg\\+WMQ\\=\\={margin-top:28px}@media screen and (min-width:900px){.AX4vI7K6WztjrMY9Hg\\+WMQ\\=\\={margin-top:36px}}.SurqHE5ZwGabEWBTW3-23A\\=\\= a{color:#6a80ef;text-decoration:none}.SurqHE5ZwGabEWBTW3-23A\\=\\= a:hover{color:#6a80ef;border-color:#6a80ef}.-kJtiglViE5Ei4fq4q4vYw\\=\\={display:grid;grid-gap:12px;grid-template-columns:-webkit-max-content 24px;grid-template-columns:max-content 24px;align-items:center;margin-top:32px;color:#6a80ef;text-decoration:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_5Bg7Tgk1RuUwgaI0R59e4A==",
	"white": "s1YMkI09bZLcriRhtpEP-A==",
	"content": "BE1dIrvt8SRz0CMOP-y+bQ==",
	"inner": "XZLqK5clWFaFfoOx9+XoVg==",
	"title": "gss29k1XxDsjtf+XjsIt3Q==",
	"items": "AX4vI7K6WztjrMY9Hg+WMQ==",
	"answer": "SurqHE5ZwGabEWBTW3-23A==",
	"link": "-kJtiglViE5Ei4fq4q4vYw=="
};
module.exports = ___CSS_LOADER_EXPORT___;
