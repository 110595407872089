// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._757-iE8U2VAWilsuIJABMw\\=\\={padding-top:32px;padding-bottom:32px;color:#fff;background-color:#191d25}@media screen and (min-width:900px){._757-iE8U2VAWilsuIJABMw\\=\\={padding-top:80px;padding-bottom:80px}}.BpMKvQpY1HqXxJvRmqMUFQ\\=\\={margin:0;font-family:\"Favorit Pro\",Roboto,sans-serif;font-weight:500;font-size:32px;line-height:40px;text-align:center}@media screen and (min-width:900px){.BpMKvQpY1HqXxJvRmqMUFQ\\=\\={font-size:48px;line-height:58px}}.gp0pU-eJuIBVHPR-RskjSg\\=\\={display:grid;grid-gap:24px;margin-top:24px}@media screen and (min-width:600px){.gp0pU-eJuIBVHPR-RskjSg\\=\\={grid-gap:24px;grid-template-columns:repeat(2,1fr);align-items:flex-start;margin-top:32px}}@media screen and (min-width:900px){.gp0pU-eJuIBVHPR-RskjSg\\=\\={grid-gap:32px;grid-template-columns:repeat(3,1fr);margin-top:40px}}@media screen and (min-width:1200px){.gp0pU-eJuIBVHPR-RskjSg\\=\\={grid-template-columns:repeat(auto-fit,minmax(100px,1fr))}}._1-SQbyxTi8j72c9o3BQ3Pg\\=\\={position:relative;padding:70px 24px 32px;background:linear-gradient(110.14deg,#1f242e 1.09%,#252a34);border-radius:24px}.cIhiepT4fqhs9f7-k0vkKg\\=\\={position:absolute;top:0;left:0;display:flex;align-items:center;justify-content:center;width:81px;height:46px;font-size:12px;line-height:18px;background:#6a80ef;border-radius:24px 0 24px 0}.ZC2RUsCGisDV5snc6r20Mw\\=\\={font-size:18px;line-height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_757-iE8U2VAWilsuIJABMw==",
	"title": "BpMKvQpY1HqXxJvRmqMUFQ==",
	"items": "gp0pU-eJuIBVHPR-RskjSg==",
	"item": "_1-SQbyxTi8j72c9o3BQ3Pg==",
	"badge": "cIhiepT4fqhs9f7-k0vkKg==",
	"text": "ZC2RUsCGisDV5snc6r20Mw=="
};
module.exports = ___CSS_LOADER_EXPORT___;
