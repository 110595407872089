//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import merge from 'lodash/merge';

export default {
  name: 'LandingSupport',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    baseConfig() {
      return merge({}, this.$siteConfig.LANDING.CORE, this.$siteConfig.LANDING.SUPPORT, this.config);
    },
    phones() {
      return this.baseConfig.PHONES || [];
    },
    socials() {
      const items = this.baseConfig.SOCIALS || [];

      return items.filter((item) => item.link);
    },
  },
};
