import { Term } from '@/utils/constants';
import { GenetiveDurationMap, NominativeDurationMap } from '@/utils/maps';
import { plural } from './plural';

export function term(val, isGenetive) {
  if (!val) return val;

  const { type, value } = val;
  const map = isGenetive ? GenetiveDurationMap : NominativeDurationMap;

  if (type === Term.Day) {
    return plural(value, ...map[Term.Day]);
  }

  if (type === Term.Month && value > 12) {
    if (value % 12) {
      return `${plural(Math.floor(value / 12), ...map[Term.Year])} ${plural(value % 12, ...map[Term.Month])}`;
    }

    return plural(value / 12, ...map[Term.Year]);
  }

  return plural(value, ...map[type]);
}
