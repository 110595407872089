//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeader from '@/components/Layout/TheHeader';

import LandingProducts from '@/components/Landing/LandingProducts';
import LandingProceed from '@/components/Landing/LandingProceed';
import LandingSupport from '@/components/Landing/LandingSupport';
import LandingFAQ from '@/components/Landing/LandingFAQ';

import InterfaxHero from './InterfaxHero';
import InterfaxAbout from './InterfaxAbout';
import InterfaxFooter from './InterfaxFooter';

export default {
  name: 'Interfax',
  components: {
    TheHeader,
    InterfaxHero,
    InterfaxAbout,
    LandingProducts,
    LandingProceed,
    LandingSupport,
    LandingFAQ,
    InterfaxFooter,
  },
  head() {
    const { META } = this.$siteConfig.INTERFAX;

    return {
      title: META.TITLE,
      links: [
        { rel: 'canonical', href: META.CANONICAL },
        ...META.ADDITIONAL_LINKS,
      ],
      meta: META.ADDITIONAL_METAS,
    };
  },
  computed: {
    sections() {
      const config = this.$siteConfig.INTERFAX;
      const section = (sectionName) => ({
        ...config.CORE,
        ...config[sectionName],
      });

      return [
        {
          id: 'HERO',
          component: 'InterfaxHero',
        },
        {
          id: 'ABOUT',
          component: 'InterfaxAbout',
        },
        {
          id: 'PLATFORM_PRODUCTS',
          component: 'LandingProducts',
          props: {
            config: section('PLATFORM_PRODUCTS'),
          },
        },
        {
          id: 'PROCEED',
          component: 'LandingProceed',
          props: {
            config: section('PROCEED'),
          },
        },
        {
          id: 'NON_PLATFORM_PRODUCTS',
          component: 'LandingProducts',
          props: {
            config: section('NON_PLATFORM_PRODUCTS'),
          },
        },
        {
          id: 'FAQ',
          component: 'LandingFAQ',
          props: {
            config: section('FAQ'),
          },
        },
        {
          id: 'FOOTER',
          component: 'InterfaxFooter',
        },
      ].sort((a, b) => config[a.id]?.ORDER - config[b.id]?.ORDER);
    },
  },
  mounted() {
    const { PAGE_GTM, PAGE_GTM_CATEGORY } = {
      ...this.$siteConfig.LANDING.CORE,
      ...this.$siteConfig.INTERFAX.CORE,
    };

    this.$gtme[PAGE_GTM_CATEGORY](PAGE_GTM);
  },
};
