//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import merge from 'lodash/merge';
import { uiAccordionGroup as UiAccordionGroup, uiAccordion as UiAccordion } from '@moex/ui-framework-next';

// eslint-disable-next-line import/no-unresolved
import LandingFaqLinkIcon from '@/assets/icons/landing/LandingFaqLinkIcon.svg?inline';

export default {
  name: 'LandingFAQ',
  components: {
    UiAccordionGroup,
    UiAccordion,
    LandingFaqLinkIcon,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    baseConfig() {
      return merge(
        {},
        this.$siteConfig.LANDING.CORE,
        this.$siteConfig.LANDING.FAQ,
        this.config,
      );
    },
    items() {
      return this.baseConfig.ITEMS || [];
    },
  },
};
