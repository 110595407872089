// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fi5zcocUx5aTMAS6-JaR3Q\\=\\={background-color:#2c2c32}.isfiV90zBifDQpj1wfInWg\\=\\={display:grid;grid-gap:8px;align-items:center;justify-content:center;padding-top:32px;padding-bottom:32px}.cVEm7Ad7S0Y0zTVaryYPNg\\=\\={text-align:center}._9ai20rqTf5M2MuNlwywpfA\\=\\={display:grid;grid-gap:32px;grid-template-columns:repeat(2,-webkit-min-content);grid-template-columns:repeat(2,min-content);margin-top:8px;justify-content:center}._65zzEyOu141pYtwjDi1OYg\\=\\={font-weight:400;font-size:12px;line-height:18px;color:#e6e6eb;text-align:center}._65zzEyOu141pYtwjDi1OYg\\=\\=>div:last-child{color:#fff}._65zzEyOu141pYtwjDi1OYg\\=\\=>div:last-child a{color:#fff;cursor:pointer;text-decoration:none;border:0}._65zzEyOu141pYtwjDi1OYg\\=\\=>div:last-child a:hover{color:#e6e6eb}@media screen and (min-width:900px){.isfiV90zBifDQpj1wfInWg\\=\\={grid-template-columns:repeat(3,1fr);justify-content:center;height:66px;padding-top:8px;padding-bottom:8px}.cVEm7Ad7S0Y0zTVaryYPNg\\=\\={text-align:left}._9ai20rqTf5M2MuNlwywpfA\\=\\={grid-template-columns:repeat(2,1fr);justify-content:center}._65zzEyOu141pYtwjDi1OYg\\=\\=,._9ai20rqTf5M2MuNlwywpfA\\=\\=>a:first-child{text-align:right}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "Fi5zcocUx5aTMAS6-JaR3Q==",
	"container": "isfiV90zBifDQpj1wfInWg==",
	"rusbond": "cVEm7Ad7S0Y0zTVaryYPNg==",
	"logos": "_9ai20rqTf5M2MuNlwywpfA==",
	"copyright": "_65zzEyOu141pYtwjDi1OYg=="
};
module.exports = ___CSS_LOADER_EXPORT___;
