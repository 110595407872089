//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'InterfaxFooter',
  computed: {
    config() {
      return {
        ...this.$siteConfig.INTERFAX.CORE,
        ...this.$siteConfig.INTERFAX.FOOTER,
      };
    },
  },
};
