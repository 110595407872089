import { Term } from '@/utils/constants';

export const GenetiveDurationMap = Object.freeze({
  [Term.Day]: ['дня', 'дней', 'дней'],
  [Term.Month]: ['месяца', 'месяцев', 'месяцев'],
  [Term.Year]: ['года', 'лет', 'лет'],
});

export const NominativeDurationMap = Object.freeze({
  [Term.Day]: ['день', 'дня', 'дней'],
  [Term.Month]: ['месяц', 'месяца', 'месяцев'],
  [Term.Year]: ['год', 'года', 'лет'],
});
