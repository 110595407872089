// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XshFH51AnfwrncwMLIAxWg\\=\\={position:relative;padding:24px 0;background-color:#fafafc;text-align:center}@media screen and (min-width:900px){.XshFH51AnfwrncwMLIAxWg\\=\\={padding:40px 0}.XshFH51AnfwrncwMLIAxWg\\=\\=.Ka8fBRVp8VYZzfrhvuzRJg\\=\\={text-align:left}.XshFH51AnfwrncwMLIAxWg\\=\\=.Ka8fBRVp8VYZzfrhvuzRJg\\=\\= ._0StkUgNIwXcQZwmHx7-O8g\\=\\={justify-content:flex-start}}._30gk8hjcflQJz-z3RT4rqw\\=\\={position:absolute;top:-48px}.IjO0DSj7YIGAir0Jd4AfOg\\=\\={margin:0;font-family:\"Favorit Pro\",Roboto,sans-serif;font-weight:500;font-size:32px;line-height:40px}._5TYX2aPPSABFL16l0nq0-g\\=\\={margin-top:24px;font-weight:400;font-size:18px;line-height:24px}@media screen and (min-width:900px){._5TYX2aPPSABFL16l0nq0-g\\=\\={margin-top:36px}}.QJaMKlD2rc1WH8UflARyqw\\=\\={padding-right:0}@media screen and (min-width:600px){.QJaMKlD2rc1WH8UflARyqw\\=\\={padding-right:inherit}}._0StkUgNIwXcQZwmHx7-O8g\\=\\={display:flex;flex-wrap:nowrap;margin-left:-32px;padding:24px 16px 12px 8px;overflow:auto;text-align:left}._0StkUgNIwXcQZwmHx7-O8g\\=\\=::-webkit-scrollbar{visibility:hidden;height:8px}@media screen and (min-width:600px){._0StkUgNIwXcQZwmHx7-O8g\\=\\={flex-wrap:wrap;justify-content:center;padding:16px inherit 12px}}@media screen and (min-width:900px){._0StkUgNIwXcQZwmHx7-O8g\\=\\={display:grid;grid-template-columns:repeat(4,-webkit-min-content);grid-template-columns:repeat(4,min-content)}}@media screen and (min-width:1200px){._0StkUgNIwXcQZwmHx7-O8g\\=\\={grid-template-columns:repeat(5,-webkit-min-content);grid-template-columns:repeat(5,min-content)}}.hqerinAvlcN99BGW6BgfKA\\=\\={margin-left:24px}@media screen and (min-width:600px){.hqerinAvlcN99BGW6BgfKA\\=\\={margin-top:24px}}.QJaMKlD2rc1WH8UflARyqw\\=\\=+.eU9qSjbIz2-BrhrVWKhcPA\\=\\={margin-top:64px}@media screen and (min-width:900px){.QJaMKlD2rc1WH8UflARyqw\\=\\=+.eU9qSjbIz2-BrhrVWKhcPA\\=\\={margin-top:96px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "XshFH51AnfwrncwMLIAxWg==",
	"left": "Ka8fBRVp8VYZzfrhvuzRJg==",
	"items": "_0StkUgNIwXcQZwmHx7-O8g==",
	"anchor": "_30gk8hjcflQJz-z3RT4rqw==",
	"title": "IjO0DSj7YIGAir0Jd4AfOg==",
	"caption": "_5TYX2aPPSABFL16l0nq0-g==",
	"content": "QJaMKlD2rc1WH8UflARyqw==",
	"item": "hqerinAvlcN99BGW6BgfKA==",
	"titleContainer": "eU9qSjbIz2-BrhrVWKhcPA=="
};
module.exports = ___CSS_LOADER_EXPORT___;
