//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uiButton as UiButton } from '@moex/ui-framework-next';

export default {
  name: 'InterfaxHero',
  components: {
    UiButton,
  },
  computed: {
    config() {
      return {
        ...this.$siteConfig.INTERFAX.CORE,
        ...this.$siteConfig.INTERFAX.HERO,
      };
    },
  },
  methods: {
    onClick() {
      this.$gtme[this.config.PAGE_GTM_CLICK](this.config.GTM.clickButton);

      const navigateTo = this.config.BUTTON.NAVIGATE;
      if (navigateTo) {
        document.querySelector(navigateTo)?.scrollIntoView();
      }
    },
  },
};
