// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XlPEzLpVeXvioI5lMPJArg\\=\\={background-color:#fff;padding:32px 0}.OhoO7O3ZHiJdXCbAYDXO9A\\=\\={display:grid;grid-gap:40px}.sr\\+Mu-zTEod19tMs76Kjxg\\=\\={margin:0 auto;max-width:600px;text-align:center}.xnKFhJU0glxkMSEhlQNE6g\\=\\={margin:0;font-weight:500;font-size:32px;line-height:40px}.okbxYoBeFUYYAkNzkvcuhg\\=\\={margin-top:40px;font-weight:400;font-size:20px;line-height:30px}.C7o\\+yk88YzQGWEva8n\\+4Ag\\=\\={display:grid;grid-gap:24px}.Xt8AQzWkRSrXgVtkVaUMZA\\=\\={display:grid;grid-gap:8px;justify-content:center;padding:16px;background-color:#f3f4f6;border-radius:16px}.FwoyteeLbQwUXY3fJ7ms5Q\\=\\={height:80px}.E-B\\+8IZwqFPE2-7hWJXQMw\\=\\={height:100%;-o-object-fit:contain;object-fit:contain;-o-object-position:center;object-position:center}.sURwHPoLQJLqPwjAQdR65A\\=\\={font-size:18px;line-height:24px}@media screen and (min-width:768px){.C7o\\+yk88YzQGWEva8n\\+4Ag\\=\\={grid-template-columns:repeat(3,1fr)}}@media screen and (min-width:900px){.OhoO7O3ZHiJdXCbAYDXO9A\\=\\={grid-gap:32px;grid-template-columns:300px 1fr;align-items:flex-end}.sr\\+Mu-zTEod19tMs76Kjxg\\=\\={text-align:left}.xnKFhJU0glxkMSEhlQNE6g\\=\\={font-size:28px;line-height:36px}.okbxYoBeFUYYAkNzkvcuhg\\=\\={margin-top:28px;font-size:18px;line-height:24px}.Xt8AQzWkRSrXgVtkVaUMZA\\=\\={grid-gap:16px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"base": "XlPEzLpVeXvioI5lMPJArg==",
	"container": "OhoO7O3ZHiJdXCbAYDXO9A==",
	"content": "sr+Mu-zTEod19tMs76Kjxg==",
	"title": "xnKFhJU0glxkMSEhlQNE6g==",
	"caption": "okbxYoBeFUYYAkNzkvcuhg==",
	"items": "C7o+yk88YzQGWEva8n+4Ag==",
	"item": "Xt8AQzWkRSrXgVtkVaUMZA==",
	"imageWrapper": "FwoyteeLbQwUXY3fJ7ms5Q==",
	"image": "E-B+8IZwqFPE2-7hWJXQMw==",
	"itemTitle": "sURwHPoLQJLqPwjAQdR65A=="
};
module.exports = ___CSS_LOADER_EXPORT___;
