// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tlJpZ1qUDhjNDkmGn8XuoA\\=\\={display:flex;flex-direction:column;width:202px;padding:14px 18px;background-color:#fff;border-radius:16px;box-shadow:3px 3px 12px 0 rgba(48,48,48,.17)}.NEcTtd64UDaK\\+-6PvasvgQ\\=\\={width:100%;height:30px}.NEcTtd64UDaK\\+-6PvasvgQ\\=\\= img{max-height:100%;-o-object-fit:contain;object-fit:contain;-o-object-position:center;object-position:center}.HT-\\+j8CX3q7ujdAJRmgROg\\=\\={flex:1;color:#919294;font-size:14px;line-height:20px}.dhwUY8QBoIUW6NR2d37hwQ\\=\\={font-weight:700;font-size:32px;line-height:40px}.r0eq00nMN27Ue-caOGkXGw\\=\\={font-weight:300;font-size:16px;line-height:20px}._6CPEWS2CbgFBdSB3yh-Eeg\\=\\={width:100%}.pRb129PtZjWYZorX1BdAXQ\\=\\={display:flex;align-items:center;justify-content:center;min-height:32px;margin-top:8px;color:#999;font-size:12px;line-height:16px;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tlJpZ1qUDhjNDkmGn8XuoA==",
	"logoWrapper": "NEcTtd64UDaK+-6PvasvgQ==",
	"title": "HT-+j8CX3q7ujdAJRmgROg==",
	"rate": "dhwUY8QBoIUW6NR2d37hwQ==",
	"param": "r0eq00nMN27Ue-caOGkXGw==",
	"button": "_6CPEWS2CbgFBdSB3yh-Eeg==",
	"marketing": "pRb129PtZjWYZorX1BdAXQ=="
};
module.exports = ___CSS_LOADER_EXPORT___;
